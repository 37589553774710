<script setup>
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue';
import { updateHttpFhirService } from '@/common/api/httpFhir.service.js';
import { SplashScreen } from '@capacitor/splash-screen';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { useBnNoAutocomplete } from '@/composables/useBnNoAutocomplete.js';
// import useMercure from '@/composables/useMercure.js';
import { debounce } from 'lodash';

import { useRoute } from 'vue-router';
const route = useRoute();
import { useAuthStore } from '@/stores/auth';
import { useLockout } from '@/composables/useLockout';
import { useNotificationStore } from '@/stores/notification';

import { useMousePressed, onKeyStroke } from '@vueuse/core';
import { registerAndroidNativeBackbtn, removeBackBtnListiner } from '@/common/nativeBackButton';

const rootEl = ref(null);
const globalEventSource = ref(null);

const { resetLockoutActivityTime, lockOutCountDown, resetLockoutTimer } = useLockout();
const { pressed } = useMousePressed();

watch(
    pressed,
    debounce(
        () => {
            resetLockoutActivityTime();
        },
        1000,
        { maxWait: 2000 },
    ),
);

onKeyStroke(
    debounce(
        () => {
            resetLockoutActivityTime();
        },
        1000,

        { maxWait: 2000 },
    ),
);

const authStore = useAuthStore();
const notificationStore = useNotificationStore();
const { repairAutocomplete } = useBnNoAutocomplete();

const appSnackBarHandler = () => {
    notificationStore.shift();
};

// const handleMessage = (messageData) => {
//     notificationStore.add(messageData.id);
// };

const callWatchers = () => {
    // this.getSubscriptions(); // in mercure-mixin
    repairAutocomplete(rootEl.value.$el); // in bn-no-autocomplete-mixin
};

const handleMercureConnection = () => {
    // if no token, account or user, do nothing.  Also close eventSource if exists.
    if (!authStore.token || !authStore.account?.id || !authStore.user?.apiUser?.id) {
        if (globalEventSource.value instanceof EventSourcePolyfill) {
            globalEventSource.value.close();
        }

        return;
    }

    // if a new token is issued we want to close the old EventSource object to make way for new one.
    if (globalEventSource.value instanceof EventSourcePolyfill) {
        globalEventSource.value.close();
    }

    const url = new URL('https://bestnotesfresh.com:1337/.well-known/mercure');
    url.searchParams.append('topic', 'global');
    // Subscribe to updates of several Book resources
    url.searchParams.append('topic', 'account/' + authStore.account?.id + '/user/' + authStore.user.apiUser?.id);
    // All Review resources will match this pattern
    url.searchParams.append('topic', 'account/' + authStore.account?.id);

    // 1361-Commented Out
    // this.globalEventSource = new EventSourcePolyfill(url, {
    //     headers: {
    //         Authorization: 'Bearer ' + this.token,
    //     },
    // });
    //
    // this.globalEventSource.onmessage = (event) => {
    //     this.handleMessage(JSON.parse(event.data));
    // };
};

const currentNotification = computed(() => {
    return notificationStore.notifications && notificationStore.notifications.length ? notificationStore.notifications[0] : false;
});

const useIonContainer = computed(() => {
    return !route?.path?.includes('/print');
});

onMounted(async () => {
    updateHttpFhirService();
    if (SplashScreen) {
        await SplashScreen?.hide();
    }
    removeBackBtnListiner();
    registerAndroidNativeBackbtn();
    handleMercureConnection();

    let triggerMutations = debounce(callWatchers, 200);
    let observer = new MutationObserver(triggerMutations);

    observer.observe(rootEl.value.$el, { childList: true, subtree: true });
});

onBeforeUnmount(() => {
    if (globalEventSource.value instanceof EventSourcePolyfill) {
        globalEventSource.value.close();
    }
});

watch(
    () => authStore.fhirApiUri,
    () => {
        updateHttpFhirService();
    },
);

watch(
    () => authStore.token,
    () => {
        handleMercureConnection();
    },
);

watch(
    () => authStore.user,
    () => {
        handleMercureConnection();
    },
);
</script>

<template>
    <ion-app ref="rootEl" v-if="useIonContainer">
        <v-app :style="$vuetify.display.mdAndDown ? 'top: 12px;' : 'top: 0px;'">
            <v-theme-provider theme="lightTheme">
                <v-snackbar
                    id="appSnackBar"
                    :color="currentNotification.color || 'info'"
                    :model-value="notificationStore.notifications.length > 0"
                    location="top"
                    multi-line
                    :timeout="currentNotification.timeout || -1"
                    @update:modelValue="appSnackBarHandler"
                    data-cy="noAccessWarning"
                >
                    {{ currentNotification.message }}

                    <template #actions>
                        <v-btn variant="outlined" color="white" @click="notificationStore.shift()" data-cy="appSnackBarCloseBtn">
                            <template v-if="notificationStore.notifications.length === 1"> Close </template>
                            <template v-else> Next ({{ notificationStore.notifications.length - 1 }} more) </template>
                        </v-btn>
                    </template>
                </v-snackbar>

                <!-- Lockout Snackbar -->
                <v-snackbar :model-value="!!lockOutCountDown" color="error" top timeout="-1">
                    <div class="d-flex">
                        Screen will lock in {{ lockOutCountDown }} seconds
                        <!--                <v-btn text color="white" @click="lockScreen">Lock</v-btn>-->
                        <v-btn variant="outlined" color="white" class="ml-4" @click="resetLockoutTimer">Stay logged in</v-btn>
                    </div>
                </v-snackbar>

                <div>
                    <router-view />
                </div>
            </v-theme-provider>
        </v-app>
    </ion-app>

    <v-app ref="rootEl" v-else>
        <v-snackbar
            id="appSnackBar"
            :color="currentNotification.color || 'info'"
            :model-value="notificationStore.notifications.length > 0"
            location="top"
            multi-line
            :timeout="currentNotification.timeout || -1"
            @update:modelValue="appSnackBarHandler"
        >
            {{ currentNotification.message }}

            <template #actions>
                <v-btn variant="outlined" color="white" @click="notificationStore.shift()" data-cy="appSnackBarCloseBtn">
                    <template v-if="notificationStore.notifications.length === 1"> Close </template>
                    <template v-else> Next ({{ notificationStore.notifications.length - 1 }} more) </template>
                </v-btn>
            </template>
        </v-snackbar>
        <!-- Lockout Snackbar -->
        <v-snackbar :model-value="!!lockOutCountDown" color="error" top timeout="-1">
            <div class="d-flex">
                Screen will lock in {{ lockOutCountDown }} seconds
                <!--                <v-btn text color="white" @click="lockScreen">Lock</v-btn>-->
                <v-btn variant="outlined" color="white" class="ml-4" @click="resetLockoutTimer">Stay logged in</v-btn>
            </div>
        </v-snackbar>
        <router-view />
    </v-app>
</template>

<style>
/* Fix the snackbar margin to account for the notch */

.v-snack,
.v-snackbar__wrapper {
    margin-top: var(--ion-safe-area-top, 0) !important;
}
</style>
